.list
    table
        width: 100%
        border-collapse: collapse
        border-spacing: 0
        margin-bottom: 10px
        font-size: 12px
        background-color: white
    tr
        th
            background-color: #f0f0f0
            border: 1px solid #ddd
            padding: 8px
            text-align: left
        td
            border: 1px solid #ddd
            padding: 8px
            text-align: left

.title
    background-color: darkgrey
    color: white
    width: 100%
    padding: 3px 10px
    padding-top: 7px
    display: flex
    font-size: 17px
    span
        font-weight: bold
        flex-grow: 1

.loadingIndicator
    border: 14px solid #f3f3f3
    border-top: 14px solid #3498db
    border-radius: 50%
    width: 10px
    height: 10px
    animation: spin infinite

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)
