.main
    display: inline

.expanded
    .infoContainer
        border-radius: 5px 5px 0 0

.infoContainer
    display: inline-block
    background-color: white
    border-radius: 5px
    user-select: none
    cursor: pointer
    &:hover
        background-color: lightgrey

.info
    display: flex
    align-items: center

    >div
        margin-left: 5px
        &:last-child
            margin-right: 5px

.expandedContainer
    background-color: white
    border-radius: 0 5px 5px 5px
    max-height: 300px
    overflow-y: auto

.task
    display: flex
    align-items: center
    padding: 5px
    border-bottom: 1px solid lightgrey
    user-select: none

    &:hover
        background-color: lightgrey
        cursor: pointer
